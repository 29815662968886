import React from 'react';
import { WorkflowTriggers, WorkflowTriggersProps } from '@prenuvo/workflow-triggers';

import './WorkflowTriggers.css';
import { extractUrlProperties } from './url.util';

function WorkflowTriggersWidget(): JSX.Element {
  const { env, studyId } = extractUrlProperties();

  const api = {
    dev: 'DEV_API',
    prod: 'PROD_API',
    staging: 'DEV_API',
  }[env] as WorkflowTriggersProps['api'];

  return (
    <div className="workflow-triggers-widget">
      <WorkflowTriggers studyId={studyId} api={api} />
    </div>
  );
}

export default WorkflowTriggersWidget;
