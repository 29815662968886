type UrlEnv = 'dev' | 'prod' | 'staging';

interface UrlProperties {
  env: UrlEnv;
  studyId: string;
}

export function extractUrlProperties(): UrlProperties {
  const { hostname, pathname } = window.location;

  const env = hostname.split('.')[0];
  const studyId = pathname.split('/')[3];

  return { env: ['staging', 'dev'].includes(env) ? (env as UrlEnv) : 'prod', studyId };
}
